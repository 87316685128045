import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import userimg from "assets/images/user.png";
// import logoIcon from "assets/images/logo-icon.png";
import logoIcon from "assets/images/tjaaraLogo.svg";
import { useDispatch, useSelector } from "react-redux";
// import { sidebarToggleFunction } from "helpers/functions";
import LanguageSwitch from "components/shared/LangageSwitch";
const Header = () => {
  // const [hour, setHour] = useState("");
  // const [minutes, setMinnutes] = useState("");
  // const [second, setSecond] = useState("");
  const dispatch = useDispatch();

  const { superAdmin } = useSelector((state) => state.authentication);
  const handleClock = () => {
  
    setInterval(() => {
      // const date = new Date();
      // setHour(date.getHours());
      // setMinnutes(date.getMinutes());
      // setSecond(date.getSeconds());
    }, 1000);
  };

  const sidebarToggle = () => {
    document.querySelector("body").classList.toggle("sidebarToggled");
    document.querySelector(".overlay-s").classList.toggle("fire");
    document.querySelector("html").classList.toggle("offScroll");
  };

  useEffect(() => {
    handleClock();
    // dispatch(getCurrentUser());
  }, [dispatch]);
  return (
    <>
      <div className="main-head">
        <div className="logo">
          <div className="inner">
            <NavLink to="/">
              <div className="logo-icon">
                <img src={logoIcon} alt="" />
              </div>
              {/* <div className="logo-info">
                <img src={logoInfo} alt="" />
              </div> */}
            </NavLink>
          </div>
        </div>
        <div className="head-wrp">
          <div className="burgerBtn">
            <button className="toggleSidebar" onClick={sidebarToggle}>
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.75 1C15.75 0.585786 15.4142 0.25 15 0.25H6C5.58579 0.25 5.25 0.585786 5.25 1C5.25 1.41421 5.58579 1.75 6 1.75H15C15.4142 1.75 15.75 1.41421 15.75 1Z"
                  fill="#346BF4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.75 13C15.75 12.5858 15.4142 12.25 15 12.25H9C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75H15C15.4142 13.75 15.75 13.4142 15.75 13Z"
                  fill="#346BF4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.75 7C15.75 6.58579 15.4142 6.25 15 6.25H1C0.585787 6.25 0.25 6.58579 0.25 7C0.25 7.41421 0.585787 7.75 1 7.75H15C15.4142 7.75 15.75 7.41421 15.75 7Z"
                  fill="#346BF4"
                />
              </svg>
            </button>
          </div>
          <div className="searchWrap">
            <form action="#">
              <div className="form-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="بحث"
                ></input>

                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          {/* <div className="timeWrap">
            <span>{hour}</span>
            <span>{minutes} :</span>
            <span>{second} :</span>
          </div> */}
          <div className="authWrap">
            <LanguageSwitch />
            <ul>
              <li className="menu-item-has-children">
                <Dropdown>
                  <Dropdown.Toggle>
                    <div className="au-flow">
                      <div className="au-img">
                        <img src={userimg} alt="" />
                      </div>
                      <div className="au-data">
                        <h4>{superAdmin?.username}</h4>
                        <p>{superAdmin?.role?.name}</p>
                      </div>
                    </div>
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.14376 6.13396L1.06422 2.05441C0.867468 1.85766 0.867468 1.53867 1.06422 1.34194L1.54003 0.866126C1.73645 0.66971 2.05478 0.669332 2.25166 0.865287L5.5 4.09841L8.74832 0.865287C8.9452 0.669332 9.26353 0.66971 9.45995 0.866126L9.93576 1.34194C10.1325 1.53869 10.1325 1.85768 9.93576 2.05441L5.85625 6.13396C5.65949 6.3307 5.34051 6.3307 5.14376 6.13396Z"
                        fill="black"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <NavLink to="/logout" className="dropdown-item">
                      خروج
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
          {/* <div className="noificationWrap">
            <ul>
              <li className="menu-item-has-children">
                <Dropdown>
                  <Dropdown.Toggle>
                    <span>
                      <svg
                        width="26"
                        height="28"
                        viewBox="0 0 26 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.45251 25.6682C10.0606 27.0357 11.4091 28 13.0006 28C14.5922 28 15.9407 27.0357 16.5488 25.6682C15.4266 25.7231 14.2596 25.76 13.0006 25.76C11.7418 25.76 10.5748 25.7231 9.45251 25.6682Z"
                          fill="#1B2559"
                        ></path>
                        <path
                          d="M25.3531 19.74C23.8769 17.8785 21.3995 14.2195 21.3995 10.64C21.3995 7.09073 19.1192 3.89758 15.7995 2.72382C15.7592 1.21406 14.5183 0 13.0006 0C11.4819 0 10.2421 1.21406 10.2017 2.72382C6.88095 3.89758 4.60064 7.09073 4.60064 10.64C4.60064 14.2207 2.12434 17.8785 0.647062 19.74C0.154273 20.3616 0.00191325 21.1825 0.240515 21.9363C0.473484 22.6721 1.05361 23.2422 1.79282 23.4595C3.08755 23.8415 5.20991 24.2715 8.44676 24.491C9.84785 24.5851 11.3543 24.64 13.0007 24.64C14.646 24.64 16.1524 24.5851 17.5535 24.491C20.7914 24.2715 22.9127 23.8415 24.2085 23.4595C24.9477 23.2422 25.5268 22.6722 25.7597 21.9363C25.9983 21.1825 25.8448 20.3616 25.3531 19.74Z"
                          fill="#1B2559"
                        ></path>
                      </svg>
                      <i className="badgo">12</i>
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul>
                      <li>
                        <div className="i-img">
                          <img src={userimg} alt="" />
                        </div>
                        <div className="i-data">
                          <p>
                            لقيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء
                            الدالة البناء
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="i-img">
                          <img src={userimg} alt="" />
                        </div>
                        <div className="i-data">
                          <p>
                            لقيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء
                            الدالة البناء
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="i-img">
                          <img src={userimg} alt="" />
                        </div>
                        <div className="i-data">
                          <p>
                            لقيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء
                            الدالة البناء
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="i-img">
                          <img src={userimg} alt="" />
                        </div>
                        <div className="i-data">
                          <p>
                            لقيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء
                            الدالة البناء
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="i-img">
                          <img src={userimg} alt="" />
                        </div>
                        <div className="i-data">
                          <p>
                            لقيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء
                            الدالة البناء
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="i-img">
                          <img src={userimg} alt="" />
                        </div>
                        <div className="i-data">
                          <p>
                            لقيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء
                            الدالة البناء
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="i-img">
                          <img src={userimg} alt="" />
                        </div>
                        <div className="i-data">
                          <p>
                            لقيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء
                            الدالة البناء
                          </p>
                        </div>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="overlay-s" onClick={sidebarToggle}></div>
    </>
  );
};

export default Header;
