import ControlArea from "components/Layout/ControlArea";
import React, { useEffect, useState } from "react";
import blogIcon from "assets/svgs/blog.svg";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getBlogs } from "store/actions";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const dispatch = useDispatch();
  const { blogs, metadata } = useSelector((state) => state.blogs);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pageNumberFromQuery = parseInt(queryParams.get("page")) || 1;
  const pageSizeFromQuery = parseInt(queryParams.get("limit")) || 10;

  const [page, setPage] = useState(pageNumberFromQuery);
  const [limit, setLimit] = useState(pageSizeFromQuery);

  useEffect(() => {
    // Fetch users when page or page size changes
    dispatch(getBlogs({ page, limit }));

    // Update URL with pageNumber and pageSize
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("page", page);
    newUrl.searchParams.set("limit", limit);
    window.history.replaceState({}, "", newUrl);
  }, [dispatch, page, limit]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setLimit(Number(e.target.value));
  };
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="AddBlog" />}
          cardTxt={<FormattedMessage id="blogs" />}
          icon={blogIcon}
          url="/portal/blogs/addblog"
        />
        <Table blogs={blogs} page={page} limit={limit} />
        <div className="table--pagination">
          <PaginationControl
            page={page}
            between={2}
            total={metadata?.totalResults || 1}
            limit={metadata?.pagination?.limit || 1}
            changePage={handlePageChange}
            ellipsis={2}
          />
          <select
            className="form-control form-select"
            value={limit}
            onChange={handlePageSizeChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Index;
