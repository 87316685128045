import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, Row } from "react-bootstrap";
import Loader from "components/shared/Loader";
import { getItems } from "store/actions";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DashboardIcon } from "assets/svgs/dashboard-active.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";

import "styles/permissions.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteItem } from "store/actions";
import { getId } from "helpers/functions";

const Roles = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const { permissionsData, loading } = useSelector(
    (state) => state.permissionsData
  );

  useEffect(() => {
    // const paramsStr =
    //   searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    // dispatch(getItems({ dataKey: `permissions${paramsStr}` }));

    dispatch(getItems({ dataKey: "roles" }));
    // dispatch(getItems({ dataKey: "groups" }));
  }, [dispatch, searchParams]);

  const handleEditPermission = (id) => {
    navigate(`/permissions/roles/${id}`);
  };

  const handleDeletePermission = (name, id) => {
    Swal.fire({
      title: `${formatMessage({ id: "delete" })} ${formatMessage({
        id: "role",
      })} ${name}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1b659e",
      cancelButtonColor: "#cc4949",
      confirmButtonText: formatMessage({ id: "Yes" }),
      cancelButtonText: formatMessage({ id: "NO" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteItem({ id, dataKey: "roles" }));
      }
    });

    // dispatch(deleteAdmin(id));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="permissions">
        <div className="breadcrumb">
          <h2 className="active">
            <DashboardIcon />
            <FormattedMessage id="dashboard" />
          </h2>
          <h2>&gt;</h2>
          <h2>
            <FormattedMessage id="permissions" />
          </h2>
          <h2>&gt;</h2>
          <h2>
            <FormattedMessage id="roles" />
          </h2>
        </div>

        <Link to="/permissions/roles/add-role" className="addRoleBtn">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4286 6.39286H9.60714V1.57143C9.60714 0.979799 9.12734 0.5 8.53571 0.5H7.46429C6.87266 0.5 6.39286 0.979799 6.39286 1.57143V6.39286H1.57143C0.979799 6.39286 0.5 6.87266 0.5 7.46429V8.53571C0.5 9.12734 0.979799 9.60714 1.57143 9.60714H6.39286V14.4286C6.39286 15.0202 6.87266 15.5 7.46429 15.5H8.53571C9.12734 15.5 9.60714 15.0202 9.60714 14.4286V9.60714H14.4286C15.0202 9.60714 15.5 9.12734 15.5 8.53571V7.46429C15.5 6.87266 15.0202 6.39286 14.4286 6.39286Z"
              fill="white"
            />
          </svg>

          {formatMessage({ id: "addRole" })}
        </Link>
        <div className="titlePage-div">
          <p className="description">
            <FormattedMessage id="rolesAreAssignedToGrantSpecificPermissionsToDifferentTypesOfUsersWithinYourWorkspace. ThisProvidesTheWorkspaceOwnerAndAdministratorsGreaterControlOverWhoCanPostWhat, EspeciallyForLargerProjects." />
          </p>
        </div>

        <div className="allRoles">
          <Row>
            {permissionsData?.roles?.map((item) => (
              <Col key={getId(item)} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <div className="oneRole">
                  <div className="title">
                    <div>
                      <h3>{item?.name}</h3>
                      <h4>{item?.description}</h4>
                    </div>
                  </div>
                  <div className="roleName">
                    <h3>
                      <FormattedMessage id="user2" />
                    </h3>
                  </div>
                  <div className="global-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        as="button"
                        className="btn btn-secondary"
                      >
                        <DotsIcon width="20" height="40" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => handleEditPermission(getId(item))}
                        >
                          <EditIcon className="bi bi-pencil edit" />
                          <FormattedMessage id="edit" />
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) =>
                            handleDeletePermission(
                              item?.name?.[locale] || item?.name,
                              getId(item)
                            )
                          }
                        >
                          <DeleteIcon
                            className="bi bi-trash delete"
                            fill="#fff"
                          />
                          <FormattedMessage id="delete" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Roles;
