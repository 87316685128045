import ControlArea from "components/Layout/ControlArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import themesIcon from "assets/svgs/themes.svg";
import { ReactComponent as GridIcon } from "assets/svgs/gridLayout.svg";
import { ReactComponent as ListIcon } from "assets/svgs/listLayout.svg";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import { ReactComponent as FilterIcon } from "assets/svgs/filter.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import Swal from "sweetalert2";
import CardsList from "components/themes/CardsList";
import { useIntl, FormattedMessage } from "react-intl";
import { getId } from "helpers/functions";
import {
  deleteThemesComponent,
  getThemeComponents,
  getThemesComponentsTypes,
} from "store/actions";
import FilterSidebar from "components/shared/FilterSidebar";
import ParamsFilters from "components/shared/ParamsFilters";
import useSearchFormParam from "components/shared/SearchHook";
// import { Dropdown } from "react-bootstrap";

const Index = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [registerSearch, handleSubmitSearch, searchParams, EmptyButton] =
    useSearchFormParam();

  const { components } = useSelector((state) => state.components);
  const { componentsTypes } = useSelector((state) => state.componentsTypes);
  const deviceType = searchParams.get("deviceType");

  const [isCheck, setIsCheck] = useState([]);

  useEffect(() => {
    searchParams.delete("deviceType");
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    searchParams.set("deviceType", deviceType);
    dispatch(getThemeComponents({ deviceType, searchParams: paramsStr }));
  }, [deviceType, dispatch, searchParams]);

  useEffect(() => {
    // dispatch(getThemeComponents(deviceType));
    dispatch(getThemesComponentsTypes(deviceType));
  }, [deviceType, dispatch]);

  const [listView, setListView] = useState(false);
  // const [filterText, setFilterText] = useState("");
  // const [showFilter, setShowFilter] = useState(false);

  // const filteredItems = components?.filter(
  //   (item) =>
  //     JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
  //     -1
  // );

  const handleSelectAll = () => {
    if (isCheck?.length === components?.length) return setIsCheck([]);
    setIsCheck(components.map((item) => getId(item)));
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((product) => product !== id));
    }
  };

  const handleDelete = () => {
    Swal.fire({
      
      title:  formatMessage({id:"doYouWantToDeleteElement"})(isCheck?.length),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText:  formatMessage({ id: "Yes" }),
      cancelButtonText: formatMessage({ id: "NO" }),
    }).then((result) => {
      if (result.isConfirmed) {
        isCheck?.map((id) => dispatch(deleteThemesComponent(id)));
        setIsCheck([]);
      }
    });
  };

  const CheckedOptions = () =>
    isCheck?.length > 0 && (
      <div className="checked-options">
        <button type="button" className="btn btn-danger" onClick={handleDelete}>
          <DeleteIcon fill="#fff" />
        </button>
      </div>
    );

  const filterToggle = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };

  const filterLength = [...searchParams.entries()]
    ?.map((e) => e[0])
    ?.filter(
      (e) =>
        e !== "page" && e !== "limit" && e !== "nameText" && e !== "deviceType"
    )?.length;

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="Add Item" />}
          cardTxt={<FormattedMessage id="Items" />}
          icon={themesIcon}
          url={`/themes/components/add?deviceType=${deviceType}`}
        />
        <div className="filter-toolbar">
          <div className="filter-toolbar__right">
            <div className="filter-toolbar__selection">
              <label htmlFor="select-all">
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheck?.length}
                />
                <h5>
                  <FormattedMessage id="allComponents" />{" "}
                  {` (${components?.length || ""})`}
                </h5>
                {/* <SelectProducts /> */}
              </label>
            </div>
            <div className="filter-toolbar__layout">
              <button
                className={listView ? "gridLayout" : "gridLayout active"}
                onClick={() => setListView(false)}
              >
                <GridIcon fill="#F2F2F2" stroke="#292D32" />
              </button>
              <button
                className={listView ? "listLayout active" : "listLayout"}
                onClick={() => setListView(true)}
              >
                <ListIcon fill="#292D32" stroke="#292D32" />
              </button>
            </div>
          </div>
          <div className="filter-toolbar__left">
            <div className="filter-toolbar__action">
              <button
                className="btn btn-black"
                onClick={filterToggle}
                type="button"
              >
                <FilterIcon />
                <FormattedMessage id="filter" />
                {!!filterLength && (
                  <span className="filterLength">{filterLength}</span>
                )}
              </button>
            </div>
            {/* <div className="filter-toolbar__settings">
              <SettingsDropdown />
            </div> */}
            <CheckedOptions />
            <form
              className="filter-toolbar__search"
              onSubmit={handleSubmitSearch}
            >
              <input
                type="search"
                placeholder={formatMessage({ id: "nameSearch" })}
                className="form-control"
                {...registerSearch("txt")}
              />
              <EmptyButton />

              <button>
                <SearchIcon />
              </button>
            </form>
          </div>
          <ParamsFilters types={componentsTypes} href="/themes/components" />
        </div>
        <CardsList
          listView={listView}
          // metadata={metadata}
          filteredItems={components}
          isCheck={isCheck}
          handleClick={handleClick}
        />
        <FilterSidebar
          searchParams={searchParams}
          filtersObj={{
            types: {
              type: "checkbox",
              data: componentsTypes,
            },
          }}
        />
        <div className="overlay-g" onClick={filterToggle}></div>

        {/* <Table data={components} /> */}
      </div>
    </>
  );
};

export default Index;
