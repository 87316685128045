import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { deleteBank, getBanks } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import Swal from "sweetalert2";
import { getId } from "helpers/functions";

const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
const intl=useIntl()
  useEffect(() => {
    dispatch(getBanks());

    const timeout = setTimeout(() => {
      setRows(rows);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch, rows]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEditBank = (id) => {
    navigate(`/banks/${id}`);
  };

  const handleDeleteBank = (id) => {
    Swal.fire({
      title: formatMessage({ id: "deleteBank" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText:  formatMessage({ id: "Yes" }),
      cancelButtonText: formatMessage({ id: "NO" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBank(id));
      }
    });
  };

  const columns = [
    {
      name: "",
      selector: (row) => <img src={row?.logo} alt="" className="bank-logo" />,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "Name" }),
      selector: (row) => row?.name?.[locale],
      sortable: true,
    },

    {
      cell: (row) => (
        <>
          <div className="global-dropdown">
            <Dropdown>
              <Dropdown.Toggle>
                <DotsIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => handleEditBank(getId(row))}>
                  <EditIcon className="bi bi-pencil edit" />
                  <FormattedMessage id="Edit" />
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleDeleteBank(getId(row))}>
                  <DeleteIcon className="bi bi-trash delete" fill="#fff" />
                  <FormattedMessage id="Delete" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                {/* <h4>جدول البيانات الافتراضي </h4> */}
                <h4>
                  <FormattedMessage id="defaultDatatable" />
                </h4>
                <p>
                  {/* القيام به لاستخدامه مع الجداول الخاصة بك هو لاستدعاء الدالة
                  البناء: */}
                  <FormattedMessage id="toUseThisWithYourTables,SimplyCallTheConstructorFunction" />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              //selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              //selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
