import server from "./server";
export const getThemesApi = async ({ deviceType, searchParams }) => {
  const response = await server().get(`/themeComponents${searchParams}`, {
    headers: {
      deviceType: deviceType,
    },
  });
  return response.data;
};

export const getSingleThemeApi = async (id) => {
  const response = await server().get(`/themeComponents/${id}`);
  return response.data;
};

export const addThemeApi = async (payload) => {
  const response = await server().post("/themeComponents", payload?.data, {
    headers: {
      deviceType: payload?.deviceType,
    },
  });
  return response.data;
};

export const editThemeApi = async ({ id, data }) => {
  const response = await server().put(`/themeComponents/${id}`, data);
  return response.data;
};

export const deleteThemeApi = async (id) => {
  const response = await server().delete(`/themeComponents/${id}`);
  return response.data;
};
