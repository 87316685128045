import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import Loader from "components/shared/Loader";
import { deleteSeoRedirect } from "store/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getId } from "helpers/functions";
import Swal from "sweetalert2";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";

const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleDeleteContact = (id) => {
    Swal.fire({
      title: `${formatMessage({ id: "delete" })} ${formatMessage({
        id: "seoRedirect",
      })}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1b659e",
      cancelButtonColor: "#cc4949",
      confirmButtonText: formatMessage({id:"Yes"}),
      cancelButtonText: formatMessage({id:"NO"}),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSeoRedirect(id));
      }
    });

    // dispatch(deleteSeoRedirect(id));
  };

  const handleShowContact = (id) => {
    navigate(`/seoRedirects/editSeoRedirects/${id}`);
  };

  const columns = [
    {
      name: <FormattedMessage id={"source"} />,
      selector: (row) => (
        <div
          className="overflow-hidden"
          style={{ maxWidth: "40vw", direction: "ltr" }}
          title={row?.source}
        >
          {row?.source}
        </div>
      ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"destination"} />,
      selector: (row) => (
        <div
          className="overflow-hidden"
          style={{ maxWidth: "40vw", direction: "ltr" }}
          title={row?.destination}
        >
          {row?.destination}
        </div>
      ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"redirectCode"} />,
      selector: (row) => row?.redirectCode,
      sortable: true,
      width: "10%",
    },
    {
      cell: (row) => (
        <>
          {
            <div className="global-dropdown">
              <Dropdown>
                <Dropdown.Toggle>
                  <DotsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => handleShowContact(getId(row))}>
                    <EditIcon className="bi bi-pencil edit" />

                    <FormattedMessage id={"edit"} />
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={(e) => handleDeleteContact(getId(row))}
                  >
                    <DeleteIcon className="bi bi-trash delete" fill="#fff" />

                    <FormattedMessage id={"delete"} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id={"seoRedirectsTableShow"} />
                </h4>
                <p>
                  <FormattedMessage id={"seoRedirectsTableShowAll"} />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              // //selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              //selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
