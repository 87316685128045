import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { deleteUser, getUsers } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FormattedMessage, useIntl } from "react-intl";
import { getId } from "helpers/functions";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
// import { deleteReport } from "../../store/actions";
const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    dispatch(getUsers());

    const timeout = setTimeout(() => {
      setRows(rows);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch, rows]);

  // const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
  //   <div className="form-check">
  //     <input
  //       htmlFor="booty-check"
  //       type="checkbox"
  //       className="form-check-input"
  //       ref={ref}
  //       onClick={onClick}
  //       {...rest}
  //     />
  //     <label className="form-check-label" id="booty-check" />
  //   </div>
  // ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEditUser = (id) => {
    navigate(`/users/${id}`);
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: formatMessage({ id: "deleteUser" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: formatMessage({ id: "Yes" }),
      cancelButtonText: formatMessage({ id: "NO" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(id));
      }
    });
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "Name" }),
      selector: (row) => <span>{row?.name}</span>,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "UserName" }),
      selector: (row) => <span>{row?.username}</span>,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "email" }),
      selector: (row) => <span>{row?.email}</span>,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "mobileNumber" }),
      selector: (row) => <span>{row?.phone}</span>,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "Role" }),
      selector: (row) => <span>{row?.role}</span>,
      sortable: true,
    },
    // {
    //   name: "العناوين",
    //   selector: (row) => (
    //     <>
    //       {row?.addresses?.map((address) => {
    //         return (
    //           <div className="branch-block" key={address?.id}>
    //             <ul>
    //               <li>{address?.addressDetail}</li>
    //             </ul>
    //           </div>
    //         );
    //       })}
    //     </>
    //   ),
    //   sortable: true,
    // },
    {
      cell: (row) => (
        <>
          <div className="global-dropdown">
            <Dropdown>
              <Dropdown.Toggle>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-three-dots-vertical"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg> */}
                <DotsIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => handleEditUser(getId(row))}>
                <EditIcon className="bi bi-pencil edit" />
                  <FormattedMessage id="Edit" />
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleDeleteUser(getId(row))}>
                <DeleteIcon className="bi bi-trash delete" fill="#fff" />
                <FormattedMessage id="Delete" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id="defaultDatatable" />
                </h4>
                <p>
                  <FormattedMessage id="toUseThisWithYourTables,SimplyCallTheConstructorFunction" />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              //selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
         
             
              //selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
