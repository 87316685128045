import { getId } from "helpers/functions";
import {
  DELETE_SUBSCRIBER,
  DELETE_SUBSCRIBER_FAILURE,
  DELETE_SUBSCRIBER_SUCCESS,
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_FAILURE,
  GET_ALL_SUBSCRIBERS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  subscribers: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get subscribers
    case GET_ALL_SUBSCRIBERS:
      state = {
        ...state,
        error: "",
        loading: true,
        subscribers: [],
      };
      break;

    case GET_ALL_SUBSCRIBERS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        subscribers: action.payload?.portalSubscibes,
      };
      break;

    case GET_ALL_SUBSCRIBERS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        subscribers: [],
        loading: false,
      };
      break;

    // ADD subscribers
    case DELETE_SUBSCRIBER:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case DELETE_SUBSCRIBER_SUCCESS:
      const subscribersAfterDelete = [
        ...state?.subscribers?.filter(
          (subscriber) => getId(subscriber) !== action.payload
        ),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        subscribers: subscribersAfterDelete,
      };
      break;

    case DELETE_SUBSCRIBER_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default stores;
